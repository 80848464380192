import wrapperStyles from './wrapper-styles.scss';
import classList from './class-list';
import {
  getSdkIntegrationCssHref,
  WEBCHECKOUT_WRAPPER,
  WEBCHECKOUT_WRAPPER_STYLESHEET
} from './config';

export const DeleteIfExists = (d) => (id) => {
  const existing = d.getElementById(id);
  if (existing) {
    existing.remove();
  }
};

export const CreateElement =
  (d) =>
  ({ type = 'div', id, attrs = {}, classes = [] }) => {
    const el = d.createElement(type);
    if (id) {
      el.id = id;
    }
    Object.entries(attrs).forEach(([name, value]) => {
      el.setAttribute(name, value);
    });
    classList(...classes).add()(el.classList);
    return el;
  };

export const CreateWrapperStylesheet = (d) => () =>
  CreateElement(d)({
    id: WEBCHECKOUT_WRAPPER_STYLESHEET,
    type: 'link',
    attrs: { rel: 'stylesheet', href: getSdkIntegrationCssHref(d) }
  });

export const CreateWrapper = (d) => () =>
  CreateElement(d)({
    id: WEBCHECKOUT_WRAPPER,
    classes: [wrapperStyles.wrapper, wrapperStyles.hidden]
  });

export const CreateIframe = (d) => () =>
  CreateElement(d)({
    type: 'iframe',
    attrs: {
      frameborder: '0',
      scrolling: 'no'
    },
    classes: [wrapperStyles.iframe]
  });
