import error from '../error';
import { WEBCHECKOUT_COMPLETE } from '../../events';

export default (cookieConsent) => {
  if (cookieConsent == null) {
    return Promise.resolve({ allowStatisticalCookies: false });
  }
  if (cookieConsent.hasOwnProperty('allowStatisticalCookies')) {
    return Promise.resolve(cookieConsent);
  }
  return Promise.reject(
    error({
      type: WEBCHECKOUT_COMPLETE.INIT_ERROR,
      value:
        'If defined, cookieConsent must be an object with property allowStatisticalCookies: true | false '
    })
  );
};
