/**
 * Returns an event listener for the dispatched event:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#The_dispatched_event
 * @param {WindowProxy} expectedSource {@link HTMLIFrameElement#contentWindow}
 * from the iframe
 * @param {function(WebcheckoutEvent)} fireEvent - function to be used for
 * firing events when messages come in.
 * @returns {EventListener} EventListener that you can give to
 * {@link Window#addEventListener}
 */
export default ({ expectedSource, fireEvent }) => {
  return ({ data = {}, source }) => {
    if (source !== expectedSource) {
      return;
    }

    fireEvent(data);
  };
};
