import documentReady from 'document-ready-promise';
import error from '../error';
import { WEBCHECKOUT_COMPLETE } from '../../events';

export default (element) => {
  if (
    typeof element === 'object' &&
    typeof element.appendChild === 'function'
  ) {
    return Promise.resolve(element);
  }

  const selector = element;
  return documentReady().then(() => {
    const selected = document.querySelectorAll(selector);
    if (selected.length > 1) {
      return Promise.reject(
        error({
          type: WEBCHECKOUT_COMPLETE.ERROR,
          value: `Too many elements matching "${selector}". There are ${selected.length} of them.`
        })
      );
    }
    if (selected.length < 1) {
      return Promise.reject(
        error({
          type: WEBCHECKOUT_COMPLETE.ERROR,
          value: `Element not found with selector "${selector}".`
        })
      );
    }
    return Promise.resolve(selected[0]);
  });
};
