import error from '../error';
import { WEBCHECKOUT_COMPLETE } from '../../events';

export default (selectedMonth) => {
  if (!selectedMonth || !Number.isNaN(Number(selectedMonth))) {
    return Promise.resolve(Number(selectedMonth));
  }
  return Promise.reject(
    error({
      type: WEBCHECKOUT_COMPLETE.INIT_ERROR,
      value: 'The selected month provided is not valid.'
    })
  );
};
