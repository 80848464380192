import splitClassNames from './split-class-names';

export default (...cssModuleClasses) => {
  const classNames = splitClassNames(cssModuleClasses);
  return {
    add: () => (elementClassList) =>
      classNames.forEach((className) => elementClassList.add(className)),

    remove: () => (elementClassList) =>
      classNames.forEach((className) => elementClassList.remove(className)),

    toggle: (force) => (elementClassList) =>
      classNames.forEach((className) =>
        elementClassList.toggle(className, force)
      )
  };
};
