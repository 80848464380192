export const WEBCHECKOUT_WRAPPER = 'webcheckout-wrapper';
export const WEBCHECKOUT_WRAPPER_STYLESHEET = 'webcheckout-wrapper-stylesheet';

const getOurScriptTag = (d) =>
  d.currentScript || d.querySelector('script[src$="/sdk-v1.js"]');

const getOurScriptSrc = (d) => getOurScriptTag(d).getAttribute('src');

export const getSdkFrontendUrl = (d) =>
  getOurScriptSrc(d).replace(/sdk-v1\.js$/, '');

export const getSdkIntegrationCssHref = (d) =>
  getOurScriptSrc(d).replace(/\.js$/, '.css');
