const splitString = (s) => `${s}`.split(/[, ]+/).filter((sp) => sp.length);

const splitClassNames = (head, ...tail) => {
  if (tail.length === 0) {
    return splitString(head);
  }

  return splitString(head).concat(splitClassNames(tail));
};

export default splitClassNames;
