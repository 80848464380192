import resolveValidElement from './resolve-valid-element';
import resolveValidPurchaseRequestToken from './resolve-valid-purchase-request-token';
import resolveValidCustomCssUrl from './resolve-valid-custom-css-url';
import resolveValidPreSelectedMonth from './resolve-valid-pre-selected-month';
import resolveValidCookieConsent from './resolve-valid-cookie-consent';

export default ({
  element,
  purchaseRequestToken,
  customCssUrl,
  preSelectedMonth,
  cookieConsent
}) => {
  return Promise.all([
    resolveValidElement(element),
    resolveValidPurchaseRequestToken(purchaseRequestToken),
    resolveValidCustomCssUrl(customCssUrl),
    resolveValidPreSelectedMonth(preSelectedMonth),
    resolveValidCookieConsent(cookieConsent)
  ]).then(
    ([
      element_,
      purchaseRequestToken_,
      customCssUrl_,
      preSelectedMonth_,
      cookieConsent_
    ]) =>
      Promise.resolve({
        element: element_,
        purchaseRequestToken: purchaseRequestToken_,
        customCssUrl: customCssUrl_,
        preSelectedMonth: preSelectedMonth_,
        allowStatisticalCookies: cookieConsent_.allowStatisticalCookies
      })
  );
};
