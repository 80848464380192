import error from '../error';
import { WEBCHECKOUT_COMPLETE } from '../../events';

export default (customCssUrl) => {
  if (!customCssUrl) {
    return Promise.resolve();
  }

  if (typeof customCssUrl === 'string') {
    const urlWithoutParams = customCssUrl.split(/[?#]/)[0];
    if (
      urlWithoutParams.includes('http') &&
      urlWithoutParams.endsWith('.css')
    ) {
      return Promise.resolve(customCssUrl);
    }
  }

  return Promise.reject(
    error({
      type: WEBCHECKOUT_COMPLETE.ERROR,
      value: 'The customCssUrl provided is not a valid URL.'
    })
  );
};
