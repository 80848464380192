/**
 * Events that can be sent to the parent window.
 *
 * This is part of the external API towards the merchant.
 * Breaking changes need to be versioned.
 */

export const WEBCHECKOUT_INITIATED = 'INITIATED';
export const WEBCHECKOUT_COMPLETE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CARD_ERROR: 'CARD_ERROR',
  CANCEL: 'CANCEL',
  REDIRECT_AT_CREDIT_DENIED: 'REDIRECT_AT_CREDIT_DENIED',
  REDIRECT_AT_ORDER_AMOUNT_TOO_LOW: 'REDIRECT_AT_ORDER_AMOUNT_TOO_LOW',
  REDIRECT_AT_CONTACT_INFORMATION: 'REDIRECT_AT_CONTACT_INFORMATION'
};

// Summary visibility toggled
export const PART_PAYMENT_SUMMARY = 'partPaymentSummary';

// Selection of # of months changed
export const PART_PAYMENT_SELECTED_MONTHS = 'partPaymentSelectedMonths';

// Selection of amount per months changed
export const PART_PAYMENT_AMOUNT_PER_MONTH = 'partPaymentAmountPerMonth';

// Continue button clicked
export const CLICKED_CONTINUE_BUTTON = 'clickedContinueButton';

// Complete card payment button clicked
export const CLICKED_COMPLETE_CARD_PAYMENT_BUTTON =
  'clickedCompleteCardPaymentButton';

// Identify button clicked
export const CLICKED_IDENTIFY_BUTTON = 'clickedIdentifyButton';

// Identify button clicked
export const CLICKED_SIGN_BUTTON = 'clickedSignButton';

// Pay button clicked
export const CLICKED_PAY_BUTTON = 'clickedPayButton';

// This one is undocumented for now as it is never used. Add it to the
// documentation when/if it starts to be used.
// Payment method chosen ("partPayment")
export const CHOSEN_PAYMENT_METHOD = 'chosenPaymentMethod';

// More information visibility toggled
export const MORE_INFORMATION = 'moreInformation';

// Result of performing customer authentication
export const AUTHENTICATED = 'authentication';

// Result of performing customer authentication
export const SIGNING = 'signing';

// Result of performing payment
export const PAYMENT_PROCESSED = 'paymentProcessing';

export const CREDIT_CHECK_DENIED = 'creditCheckDenied';
