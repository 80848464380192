/* eslint-disable max-len */

/**
 * Creates and returns a coupled pair of fireEvent and addListener functions,
 * as well as a dispose function to make them forget everything and not do
 * anything ever again.
 * @returns {{fireEvent: function (WebcheckoutEvent), addListener: function (WebcheckoutEventType, WebcheckoutEventListener), dispose: function()}}
 */
export default () => {
  const listenersByEventType = new Map();
  let disposedOf = false;

  const listenersByType = (type) => {
    const listeners = listenersByEventType.get(type) || new Set();
    if (!listenersByEventType.has(type)) {
      listenersByEventType.set(type, listeners);
    }
    return listeners;
  };

  /**
   * Fire an event to registered listeners for the specified type.
   * @param {WebcheckoutEvent} event
   */
  const fireEvent = (event = {}) => {
    if (disposedOf) return;

    new Set([
      ...listenersByType(event.type).values(),
      ...listenersByType('*').values()
    ]).forEach((listener) => {
      try {
        listener(event);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Merchant's event listener function threw an Error.`, {
          error,
          event
        });
      }
    });
  };

  const shouldExposeEvent = ({ internal = false }) => !internal;
  const onlyExposedEvents = (listener) => (event) =>
    shouldExposeEvent(event) ? listener(event) : undefined;

  /**
   * Add a listener for a specific type. If type is '*', it receives all events.
   * @param {WebcheckoutEventType} type
   * @param {WebcheckoutEventListener} listener
   * @returns {function(): boolean} function you can call to remove the
   * listener, so it stops receiving events.
   */
  const addInternalListener = (type, listener) => {
    if (disposedOf) return () => false;

    const listeners = listenersByType(type);
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  /**
   * Add a listener for a specific type. If type is '*', it receives all exposed events.
   * @param {WebcheckoutEventType} type One of the exposed event types, or '*' for all of them.
   * @param {WebcheckoutEventListener} listener
   * @returns {function(): boolean} function you can call to remove the
   * listener, so it stops receiving events.
   */
  const addMerchantListener = (type, listener) =>
    addInternalListener(type, onlyExposedEvents(listener));

  /**
   * Stops this instance from doing anything, and clears up any resources. All
   * subsequent calls to any functions will be no-ops.
   */
  const disposeListeners = () => {
    disposedOf = true;
    listenersByEventType.clear();
  };

  return {
    addMerchantListener,
    addInternalListener,
    fireEvent,
    disposeListeners
  };
};
