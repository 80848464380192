import error from '../error';
import { WEBCHECKOUT_COMPLETE } from '../../events';

export default (purchaseRequestToken) => {
  if (purchaseRequestToken && purchaseRequestToken.length > 0) {
    return Promise.resolve(`${purchaseRequestToken}`);
  }
  return Promise.reject(
    error({
      type: WEBCHECKOUT_COMPLETE.ERROR,
      value: 'The purchaseRequestToken provided is not valid.'
    })
  );
};
