import documentReady from 'document-ready-promise';
import resolveValidOptionsOrReject from './resolve-valid-options-or-reject';
import {
  getSdkFrontendUrl,
  WEBCHECKOUT_WRAPPER,
  WEBCHECKOUT_WRAPPER_STYLESHEET
} from './config';
import {
  CreateWrapper,
  CreateWrapperStylesheet,
  DeleteIfExists,
  CreateIframe
} from './elements';
import receivePostMessage from './receive-post-message';
import initListeners from './init-listeners';
import classList from './class-list';
import iframeStyle from './wrapper-styles.scss';
import { WEBCHECKOUT_SDK } from '../frontend/constants/index';
import { WEBCHECKOUT_COMPLETE } from '../events';

const removeHidden = classList(iframeStyle.hidden).remove();

/**
 * Creates the initWebcheckout(options) function.
 * @param {Window} w
 * @returns {function(WebcheckoutInitOptions): Promise<WebcheckoutApi,
 * WebcheckoutEvent>}
 */

export default (w) => (options) =>
  resolveValidOptionsOrReject(options)
    .then(documentReady) // resolves to any arguments from previous step
    .then(
      ({
        element,
        purchaseRequestToken,
        preSelectedMonth,
        customCssUrl,
        allowStatisticalCookies
      }) => {
        return new Promise((resolveWebcheckoutApi) => {
          const d = w.document;
          const deleteIfExists = DeleteIfExists(d);
          const createWrapperStylesheet = CreateWrapperStylesheet(d);
          const createWrapper = CreateWrapper(d);
          const createIframe = CreateIframe(d);

          const {
            addMerchantListener,
            addInternalListener,
            fireEvent,
            disposeListeners
          } = initListeners();
          const doOnceOn = (type, resolverOrRejector) => {
            const removeListener = addInternalListener(type, (event) => {
              removeListener();
              resolverOrRejector(event);
            });
          };

          deleteIfExists(WEBCHECKOUT_WRAPPER);
          deleteIfExists(WEBCHECKOUT_WRAPPER_STYLESHEET);

          const wrapperStylesheet = createWrapperStylesheet();
          d.querySelector('head').appendChild(wrapperStylesheet);

          const iframe = createIframe();
          const wrapper = createWrapper();
          wrapper.appendChild(iframe);
          element.appendChild(wrapper);

          const hasBooted = new Promise((resolveBooted) => {
            doOnceOn('booted', resolveBooted);
          });
          addInternalListener('setHeight', (data) => {
            iframe.style.height = `${data.value}px`;
          });
          w.addEventListener(
            'message',
            receivePostMessage({
              fireEvent,
              expectedSource: iframe.contentWindow
            }),
            false
          );
          iframe.src = getSdkFrontendUrl(d);

          hasBooted.then(() => {
            removeHidden(wrapper.classList);
            resolveWebcheckoutApi({
              start: () =>
                new Promise((resolveStart, rejectStart) => {
                  doOnceOn(WEBCHECKOUT_COMPLETE.SUCCESS, resolveStart);
                  doOnceOn(WEBCHECKOUT_COMPLETE.ERROR, rejectStart);
                  doOnceOn(WEBCHECKOUT_COMPLETE.CANCEL, rejectStart);
                  iframe.contentWindow.postMessage(
                    {
                      type: 'START',
                      source: WEBCHECKOUT_SDK,
                      purchaseRequestToken,
                      customCssUrl,
                      preSelectedMonth,
                      allowStatisticalCookies
                    },
                    '*'
                  );
                }),
              /**
               * @param {WebcheckoutEventType} type - Type of event to listen
               * for
               * @param {WebcheckoutEventListener} listener - Your listener
               * function
               * @returns {function} - A function you can call later to remove
               * the listener
               */
              on: addMerchantListener,
              dispose: () => {
                disposeListeners();
                deleteIfExists(WEBCHECKOUT_WRAPPER);
                deleteIfExists(WEBCHECKOUT_WRAPPER_STYLESHEET);
              }
            });
          });
        }).catch((error) => {
          // eslint-disable-next-line no-param-reassign
          error.type = WEBCHECKOUT_COMPLETE.ERROR;
          return Promise.reject(error);
        });
      }
    );
